import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css';
import 'dayjs/locale/zh-cn';
import locale from 'element-plus/es/locale/lang/zh-cn';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import pinia from './stores'

// axios.defaults.baseURL = 'https://server.walkertour.cn';//  http://localhost:3030

const app = createApp(App);

app.use(ElementPlus,{locale});
app.use(router);
app.use(pinia);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app');

// createApp(App).use(router).mount('#app');



